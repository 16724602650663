<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="change">
      <el-tab-pane :label="$t('cs.scyp')" name="first"
        ><audios></audios
      ></el-tab-pane>
      <el-tab-pane :label="$t('cs.bdyp')" name="second">
        <el-form ref="bindformref" :model="bindform" label-width="140px">
          <el-form-item :label="$t('cs.kjmbtsy')" prop="quicksell">
            <el-select v-model="bindform.quicksell" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.jjtsy')" prop="fund">
            <el-select v-model="bindform.fund" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.cztsy')" prop="rechange">
            <el-select v-model="bindform.rechange" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.txtsy')" prop="withdrawl">
            <el-select v-model="bindform.withdrawl" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.sjyzmtsy')" prop="smsverify">
            <el-select v-model="bindform.smsverify" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.yxyzmtsy')" prop="emailverify">
            <el-select v-model="bindform.emailverify" :placeholder="$t('qxz')">
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">{{
              $t('tijiao')
            }}</el-button>
            <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import audios from '@/components/config/audio.vue'
export default {
  components: {
    audios
  },
  data() {
    return {
      activeName: 'first',
      bindform: {
        quicksell: '',
        fund: '',
        rechange: '',
        withdrawl: '',
        smsverify: '',
        emailverify: ''
      },
      bindlist: []
    }
  },
  methods: {
    async getlist() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/bindaudio')
      if (data) {
        if (data.code === 200) {
          this.bindlist = data.data.list
          this.bindform = data.data.ids
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    async onSubmit() {
      const { data } = await this.$http.post(
        '/admin/config/bindaudio',
        this.bindform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
    },
    reset() {
      this.getlist()
    },
    change() {
      if (this.activeName === 'second') {
        this.getlist()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 350px;
}
</style>
