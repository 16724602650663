<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addcarousel">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="audiollist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="name"
        :label="$t('cs.mingcheng')"
      ></el-table-column>
      <el-table-column prop="audiourl" :label="$t('cs.shiting')">
        <template slot-scope="{ row }"
          ><audio
            controls="controls"
            :src="row.audiourl"
            type="audio/mpeg"
          ></audio
        ></template>
      </el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('chanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="addref"
        :rules="addrules"
      >
        <el-form-item :label="$t('cs.mingcheng')" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cs.yinpin')" prop="audiourl">
          <el-upload
            class="upload-demo"
            :action="weburl + '/admin/config/uploadaudio'"
            :headers="headerobj"
            :on-success="upload"
            ref="uploadref"
          >
            <el-button size="small" type="primary">{{
              $t('cs.ypbtn')
            }}</el-button>
            <div slot="tip" class="el-upload__tip">
              {{ $t('cs.yptishi') }}
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      audiollist: [],
      total: 0,
      loading: true,
      // 添加/编辑数据对象
      addform: {
        name: '',
        audiourl: ''
      },
      dialogvisible: false,
      addrules: {
        name: [{ required: true, message: '请填写名称', trigger: 'blur' }]
      },
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/config/audioconfig', {
        params: this.queryinfo
      })
      this.audiollist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    async addcarousel() {
      this.dialogvisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.addref.resetFields()
      this.$refs.uploadref.clearFiles()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 提交添加分类
    submit() {
      this.$refs.addref.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/config/addaudio',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/config/delaudio', {
          id
        })
        // console.log(data)
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 音频上传成功操作
    upload(res) {
      this.addform.audiourl = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
